import { initializeApp } from 'firebase/app';
import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBuTefTJpafKpIZGYwpzYcrGkyBbo9It88",
    authDomain: "quidometer.firebaseapp.com",
    projectId: "quidometer",
    storageBucket: "quidometer.appspot.com",
    messagingSenderId: "37610610554",
    appId: "1:37610610554:web:772d90ed6024a501fa00c3",
    measurementId: "G-RCD8MZD9HV"
  };

firebase.initializeApp(firebaseConfig)

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export const authentication = getAuth(app);