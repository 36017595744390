import logo from './logo.svg';
import './App.css';
import {Form,  FloatingLabel, Button, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col} from 'react-bootstrap'
import React, {useState, useEffect} from "react";
import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import {authentication} from './firebase';
// import { arrayUnion, QueryDocumentSnapshot, runTransaction } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Alchemy, Network } from "alchemy-sdk";
import NFT from './NFT';
// import Predictions from './Predictions';
// import {handleShow} from './NFT';
// import {handleShow} from './NFT';
// import React, {useState} from "react";
// import db from "./base";
// import './Header.css'
// import HeaderCard from "./HeaderCard";
// import './sidebar.css'
// import Widget from "./Widget";
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { connectFirestoreEmulator } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
// import { storage } from "../components/config/config";
import { doc, setDoc } from "firebase/firestore"; 
import backgroundImage from './back3.jpg';



const db = firebase.firestore();
export var showing = false
export var fp
export var kontinue
let tst = "outline-warning"
let colors = {};
let tag_info;
let tag_global;
let img_global;
let article;
let nick_name;
let fnt_col;
let checked1;
let checked2;
let data_low;
let data_up = null
let jsonData
let photo
let medal
let cup
let search
// let color
// let repeto_mess;



function App() {
  // const [nfts, setResult] = useState([]);
  const [selections, selectNFT] = useState([]);
  const [data,setData]=useState(null);
  const [floor, setFp] = useState(0)
  // const [predictions, setPredictions] = useState([]);
  var [claim,setClaim]=useState(null);
  var [ins_num,setInsNum]=useState(null);
  // var [dateTimeUploaded,setDateTimeUploaded]=useState(null);
  var [dateTimeInscribed,setDateTimeInscribed]=useState(null);
  var [lastDateTransfered,setLastDateTransfered]=useState(null);
  var [satRarity,setSatRarity]=useState(null);
  var [type,setType]=useState(null);
  var [uploader,setUploader]=useState(null);
  var [buyLink,setBuyLink]=useState(null);
  var [dispute,setDispute]=useState("0");
  var [tagColor,setTagColor]=useState("outline-warning");
  var [imgg,setImg]=useState(null);
  var [repeto_mess,setRepetoMess]=useState(null);
  var [email,setEmail]=useState(null);
  var [agreed , agreeCheck] = useState(false)
  const [sixers, setSixer]=useState([]);
  const [showDisputeMessage, setShowDisputeMessage] = useState(false);
  const [showIfEmpty, setShowIfEmpty] = useState(false);
  const [claimDispute, setClaimDispute] = useState(null);
  const [disputeEmail, setDisputeEmail] = useState(null);
  const [documents, setDocuments] = useState([]);


  let type_list = ['IMAGE/WEBP', 'IMAGE/JPEG', 'IMAGE/PNG', 'IMAGE/GIF']
  let rarity_list = ['common', ]

  // https://ordinals.com/content/01824443054f35e57864354cbb7063ae976c60cc8faec09d8b1d8cf83aaf7bc8i0
// Replace YOUR_TOKEN with your Project Token
mixpanel.init('56102a2a2bae66cb04341379b84b40ea');
 
// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.
// mixpanel.identify(/* \"<USER_ID\"> */)
 
// Track an event. It can be anything, but in this example, we're tracking a Signed Up event.
// Include a property about the signup, like the Signup Type
mixpanel.track('Landed', {
  // 'Signup Type': 'Referral'
})
   // Get all the images from Storage
  // const [files, setFiles] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => (
    setShow(false),
    setRepetoMess(null),
    setClaim(null),
    setInsNum(null)
  );
  const handleShow = () => {
    mixpanel.track('showUploadModal', {
      time: new Date().toLocaleString(),
    })
    setShow(true)
  };

  const [showContest, setShowContest] = useState(false);
  const [color, setColor] = useState(null);
  const [ins_color, setInsColor] = useState(null);
  const [name_col, setNameCol] = useState(null);
  const handleContestClose = () => setShowContest(false);
  const handleContestShow = () => setShowContest(true);

  const [showAdd, setShowAdd] = useState(false);
  const handleAddClose = () => setShowAdd(false);
  const handleAddShow = () => setShowAdd(true);

  const [showAsk, setShowAsk] = useState(false);
  const handleAskClose = () => setShowAsk(false);
  const handleAskShow = () => setShowAsk(true);
  // const [isLoading, setIsLoading] = useState(true);
  // Introduce a new state variable to manage the number of displayed tags
  const [displayedTagsCounts, setDisplayedTagsCounts] = useState(10);
  const [displayedCardsCount, setDisplayedCardsCount] = useState(20);


  var cretaria_val
  var collection_name
  var url
  
  fp = floor
  let tags = [];
  // const { id } = this.props;
  // const medalImage = id === 0 ? './cup.png' : medal;

  const [darkMode, setDarkMode] = useState(true);

  // Add useEffect to handle the initial state based on user preference or other conditions
  useEffect(() => {
    // Check user preference from local storage or any other condition
    const userPrefersDarkMode = darkMode;
    setDarkMode(userPrefersDarkMode);
    getSixers()
  }, []);
  // console.log('loaded')

  // Add a class to the root element based on dark mode status
  // Add a class to the root element based on dark mode status
  useEffect(() => {
    const rootElement = document.documentElement;
    if (darkMode) {
      rootElement.classList.add('dark-mode');
      photo = "./photow.png"
      // search = "./searchw.png"
      medal = "./medalw.png"
      cup = "./cupw.png"
      setColor(null)
      setInsColor("#E3DCDA")
      setNameCol("#D9DAE0")
    } else {
      rootElement.classList.remove('dark-mode');
      photo = "./photo.png"
      // search = "./searchw.png"
      medal = "./medal.png"
      cup = "./cup.png"
      setColor('black')
      setInsColor("#3E2907")
      setNameCol("#3E2907")
    }
  }, [darkMode]);

  // Toggle dark mode when the button is clicked
  const toggleDarkMode = () => {
    // const newDarkMode = !darkMode;
    // setDarkMode(newDarkMode);
    // localStorage.setItem('darkMode', newDarkMode.toString());
    setDarkMode(!darkMode);
  };
 
  
  // var storageRef = firebase.storage().ref("images");

  // useEffect(() => {
  //   const fetchImages = async () => {

  //   let result = await storageRef.child('images').listAll();
  //       let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());
    
  //       return Promise.all(urlPromises);

  //   }
    
  //   const loadImages = async () => {
  //       const urls = await fetchImages();
  //       setFiles(urls);
  //   }
  //   loadImages();
  //   }, []);

 

  // document.body.style = 'background: #2F3035;'; 

// get data on page load
  // useEffect(() => {
  //   db.collection("predictions")
  //   .get()
  //   .then(querySnapshot => {
  //    setPredictions(querySnapshot.docs.map(doc => doc.data())); 
  //   // console.log(querySnapshot.docs.map(doc => doc.data()))
  //   // console.log(predictions)
  // });

// Function to rearrange the dictionaries based on inscription_number
function rearrangeDictionaries(array) {
  // Sort the array based on the "inscription_number" key in ascending order
  return array.sort((a, b) => a.inscriptionNum - b.inscriptionNum);
}



// Function to load more tags when the button is clicked for a specific card
const loadMoreTags = () => {
  setDisplayedTagsCounts((prevCount) => prevCount + 10);
};

// Function to load less tags when the "Load Less" button is clicked for a specific card
const loadLessTags = () => {
  setDisplayedTagsCounts((prevCount) => prevCount - 10);
};

const loadMoreCards = () => {
  setDisplayedCardsCount((prevCount) => prevCount + 50);
};

const searchTags = e=>{
  e.preventDefault();
  mixpanel.track('searchTags', {
    time: new Date().toLocaleString(),
    search: data,
  })
  setDisplayedCardsCount(20)
  setDisplayedTagsCounts(10)
  // e.preventDefault();
  if (data == null){
    alert('Kindly write a search word')
    return;
  }
    data_low = data.toLowerCase();
    data_up = data.charAt(0).toUpperCase() + data.slice(1);
    if(data_up == null){
      setShowIfEmpty(false)
    }else{
      setShowIfEmpty(true)
    }
    // var data_low_rep = data_low.replace(' ', ',')
    const data_lowArray = data_low.split(' ');
    data_lowArray.push(data_low)
    const query = db.collection("approved").where('search_array', 'array-contains-any', [data_low]);
    query.get().then((snapshot) => {
      if (snapshot.empty) {
        setShowIfEmpty(false)
        handleAskShow()
      } 
      else {
        // console.log(snapshot.docs.map(doc => doc.data()));
        const rearranged_result = rearrangeDictionaries(snapshot.docs.map(doc => doc.data()))
        // console.log(rearranged_result)
        setSixer(rearranged_result)
        let tagz = rearranged_result
        // console.log(tagz['tag_color'])
        tagz.forEach((arr,i) => {
          let col = []
          arr['tag_color'].forEach(tagz_arr => {
            col.push(tagz_arr);
          })
          colors[i] = col;
          // col = []
        });
        // console.log(colors)

          // const lastSearchDB = db.collection("lastsearched").doc();
          // // upload to lastSearched
          //   var last = {
          //     last_searched: data_lowArray
          //   }
          
          //     lastSearchDB.set(last).then(() => {
          //       // console.log("Document successfully written!");
          //   }) 
        

    }
    });
}


    // // getting sixers
    //   useEffect(() => {
    //     // if(data_up == null){
    //     //   setShowIfEmpty(false)
    //     // }else{
    //     //   setShowIfEmpty(true)
    //     // }
    //     // getLast()
    //     getSixers()
    //     // getTags()
    //     },[])

    // if (isLoading) {
    //   return <div>Loading...</div>; // Placeholder or loading indicator
    // }


    function getLastSearched () {
      return new Promise((resolve, reject) => {
        mixpanel.track('landpage', {
          time: new Date().toLocaleString()
        });
    
        db.collection('lastsearched')
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              alert('Sorry, no sessions available at the moment');
              reject('No data available');
            } else {
              let tagz = snapshot.docs.map((doc) => doc.data());
              const last = tagz[0]['last_searched'][0];
              console.log(tagz[0]['last_searched'][0]);
              resolve(last);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
  
    }

    const getLast = async (event) =>{
      event.preventDefault();
      try {
        const last = await getLastSearched();
        setData(last);
        searchTags();
      } catch (error) {
        console.log(error);
      }
    
    }


    function getSixers(){

      mixpanel.track('landpage', {
        time: new Date().toLocaleString()
      })
  
      db.collection('six')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Sorry no sessions available at the moment')
        }else{
          // console.log(snapshot.docs.map(doc => doc.data()))
          setSixer(snapshot.docs.map(doc => doc.data()));
          let tagz = snapshot.docs.map(doc => doc.data())
          // console.log(tagz[0]['tags'])
  
          // for (i in tagz.len()):

          // tagz.forEach(arr => {
          //   // arr['tags'].forEach(tagz_arr => {
          //     var [tag, color] = arr.split(',');
          //     tags.push(tag);
          //     colors.push(color);
          //   // })
          // });
          // console.log(tags, colors)
          tagz.forEach((arr,i) => {
            let col = []
            arr['tag_color'].forEach(tagz_arr => {
              col.push(tagz_arr);
            })
            colors[i] = col;
            // col = []
          });
          // console.log(colors)
        }
        // setIsLoading(false); // Update loading state once data is retrieved
      });
  
    }

    function uploadDispute(){
      var disputeRef = db.collection('dispute').doc()

      mixpanel.track('uploadDispute', {
        time: new Date().toLocaleString(),
        claim__dispute: claimDispute,
        email: disputeEmail,
      })

      // console.log('inscription num/claim: ', ins_num, claim)
      if(claimDispute === null){
        alert('Kindly fill all questions')
        return;
      }

      if(disputeEmail == null){
        alert('Kindly fill all questions')
        return;
      }


      // upload to approval
      var docData = {
        dispute: claimDispute, 
        email_dispute: disputeEmail,
      }

        disputeRef.set(docData).then(() => {
          // console.log("Document successfully written!");
          setShowContest(false)
          setClaimDispute(null)
          setDisputeEmail(null)
          alert('Your Dispute request has been submitted successfully')
      }) 


    }


    function uploadClaimRequest(){
      var claimersRef = db.collection('approval').doc()
      mixpanel.track('uploadClaimRequest', {
        time: new Date().toLocaleString(),
        inscription_num: ins_num,
        claim: claim,
        email: email,
        uploader: uploader,
        agreed: agreed
      })

      // console.log('inscription num/claim: ', ins_num, claim)
      if(ins_num === null){
        alert('Kindly fill all questions')
        return;
      }

      if(claim == null){
        alert('Kindly fill all questions')
        return;
      }

      if(email == null){
        alert('Kindly fill all questions')
        return;
      }

      if(uploader == null){
        alert('Kindly fill all questions')
        return;
      }

      if (agreed == false){
        alert("Right...You can't swear?")
        return
      }
      
      claim = claim.toLowerCase()
      // const claim_rep = claim.replace(/ /g,'')
      // const claimArray = claim_rep.split(',');
      const query = db.collection("approved").where('inscriptionNum', '==', Number(ins_num));
    query.get().then((snapshot) => {
      if (snapshot.empty) {

        // upload to approval
          var docData = {
            inscriptionNum: ins_num, 
            claim: claim,
            uploader:uploader,
            uploader_email: email
          }
          // console.log(ins_num)
          // console.log(typeof ins_num)
          // alert('Your claim request has been uploaded successfully')
            claimersRef.set(docData).then(() => {
              // console.log("Document successfully written!");
              setShow(false)
              setInsNum(null)
              setClaim(null)
              alert('Your claim request has been uploaded successfully')
          }) 
        
      } 
      else {
        // let them know which one already exists
        // console.log(snapshot.docs.map(doc => doc.data()));
        const tagz = snapshot.docs.map(doc => doc.data())
        // const tagz_arr = tagz[0].tags
        const ins_num_rep = tagz[0].inscriptionNum
        // let repeto = findCommonStrings(claimArray, tagz_arr)
        // repeto = repeto.toUpperCase
        setRepetoMess('Sorry, Inscription #' +  ins_num_rep + ' has already been declared, pls try again')
    }})

     
    }

    function findCommonStrings(arr1, arr2) {
      // Convert arrays to sets for efficient lookup
      const set1 = new Set(arr1);
      const set2 = new Set(arr2);
    
      // Initialize an empty array to store common elements
      const commonStrings = [];
    
      // Iterate over the first array
      for (const str of set1) {
        // Check if the current element exists in the second array
        if (set2.has(str)) {
          // If it does, add it to the commonStrings array
          commonStrings.push(str);
        }
      }
    
      return commonStrings;
    }

  function multiplyString(str, times) {
      const result = [];
      for (let i = 0; i < times; i++) {
        result.push(str);
      }
      return result;
    }

  function deleteTags(){

      mixpanel.track('deleteTags', {
        time: new Date().toLocaleString(),
        inscription_num: ins_num,
        claim: claim,
        email: email,
        uploader: uploader,
        agreed: agreed
      })
      
      // var sat_rarity = jsonData.sat_rarity
      // var id = jsonData.id
      // var content_type = jsonData.content_type
      var approvedRef = db.collection('approved').doc()

      if(ins_num === null){
        alert('Kindly fill all questions')
        return;
      }

      if(claim == null){
        alert('Kindly fill all questions')
        return;
      }

      if(email == null){
        alert('Kindly fill all questions')
        return;
      }

      if(uploader == null){
        alert('Kindly fill all questions')
        return;
      }

      if (agreed == false){
        alert("Right...You can't swear?")
        return
      }

      claim = claim.toLowerCase()
      claim = claim.replaceAll(', ', ',');
      // var search_rep = claim.replace(/ /g, ',')
      // const just_claim_arr_add = claim.split(',')
      // const searchArray = search_rep.split(',');
      // const merged_search_arr = just_claim_arr_add.concat(searchArray)
      // claim = claim.replace(', ', ',');
      const claimArray = claim.split(',');
      // claimArray = claimArray.replace(' ', '')
      // var claimLen = claimArray.length
      // const tagColorArray = multiplyString(tagColor, claimLen)
      // const disputeArray = multiplyString(dispute, claimLen)
      // const dateTimeInscribedConv = convertDateFormat(dateTimeInscribed)
      // const lastDateTransferedConv = convertDateFormat(lastDateTransfered)
      // const dateTimeUploaded = getCurrentDateTimeString()
      const ins_num_int = parseInt(ins_num)
      // const type_low = type.toLowerCase()
      // console.log('inscription num/claim: ', ins_num, claim)

      const query = db.collection("approved").where('inscriptionNum', '==', Number(ins_num));
      query.get().then((snapshot) => {
        if (snapshot.empty) {
          
          // var docData = {
          //   inscriptionNum: ins_num_int, 
          //   tags: claimArray,
          //   dateTimeUploaded:dateTimeUploaded,
            // dateTimeInscribed:dateTimeInscribedConv,
            // lastDateTransfered:lastDateTransferedConv,
            // img:imgg,
          //   satRarity:sat_rarity,
          //   type:content_type,
          //   uploader:uploader,
          //   buyLink:id,
          //   dispute:disputeArray,
          //   tag_color:tagColorArray,
          //   uploader_email: email,
          //   // TODO: test this
          //   search_array: merged_search_arr
          // }
    
            // approvedRef.set(docData).then(() => {
              // console.log("Document successfully written!");
              // setShow(false)
              // setInsNum(null)
              // setClaim(null)
              alert('Delete Unsuccefull as ' +  ins_num_int + "does not exists yet")
          // })
          
        } else {
          // // let them know which one already exists
          // const tagz = snapshot.docs.map(doc => doc.data())
          // const ins_num_rep = tagz[0].inscriptionNum
          // setRepetoMess('Sorry, Inscription #' +  ins_num_rep + ' has already been declared, pls try again')
          const uid_arr = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))
          const uid = uid_arr[0].id
          const collectionRef = db.collection('approved');
          const documentRef = collectionRef.doc(uid);

          // Update the document
          documentRef.update({
            tags: firebase.firestore.FieldValue.arrayRemove(...claimArray),
            // search_array: firebase.firestore.FieldValue.arrayUnion(...merged_search_arr)
          })
            .then(() => {
            alert('Your tags has successfully been deleted from Inscription #' +  ins_num_int)
          })
            .catch((error) => {
              alert('error deleting tags, please try again')
            });

          // alert('Your tags has successfully been added to Inscription #' +  ins_num_int)
        
        }})

     
    }

    const addDocumentAndDeleteOldest = async () => {
      const collectionRef = db.collection('six');
    
      // Add new document
      // await collectionRef.add(newDocumentData);
    
      // Retrieve all documents in the collection
      const snapshot = await collectionRef.orderBy('dateTimeUploaded', 'asc').get();
    
      // Check if the number of documents exceeds the maximum allowed
      if (snapshot.size > 8) {
        const oldestDocument = snapshot.docs[0];
        console.log('oldest: ', oldestDocument)
        // Delete the oldest document
        await oldestDocument.ref.delete();
      }
    };
    
    function uploadClaimRequest2(){

      mixpanel.track('uploadClaimRequest', {
        time: new Date().toLocaleString(),
        inscription_num: ins_num,
        claim: claim,
        email: email,
        uploader: uploader,
        agreed: agreed
      })
      
      var sat_rarity = jsonData.sat_rarity
      var id = jsonData.id
      var content_type = jsonData.content_type
      var approvedRef = db.collection('approved').doc()
      var sixRef = db.collection('six').doc()

      if(ins_num === null){
        alert('Kindly fill all questions')
        return;
      }

      if(claim == null){
        alert('Kindly fill all questions')
        return;
      }

      if(email == null){
        alert('Kindly fill all questions')
        return;
      }

      if(uploader == null){
        alert('Kindly fill all questions')
        return;
      }

      if (agreed == false){
        alert("Right...You can't swear?")
        return
      }

      claim = claim.toLowerCase()
      claim = claim.replaceAll(', ', ',');
      var search_rep = claim.replace(/ /g, ',')
      const just_claim_arr_add = claim.split(',')
      const searchArray = search_rep.split(',');
      const merged_search_arr = just_claim_arr_add.concat(searchArray)
      // claim = claim.replace(', ', ',');
      const claimArray = claim.split(',');
      // claimArray = claimArray.replace(' ', '')
      var claimLen = claimArray.length
      const tagColorArray = multiplyString(tagColor, claimLen)
      const disputeArray = multiplyString(dispute, claimLen)
      // const dateTimeInscribedConv = convertDateFormat(dateTimeInscribed)
      // const lastDateTransferedConv = convertDateFormat(lastDateTransfered)
      const dateTimeUploaded = getCurrentDateTimeString()
      const ins_num_int = parseInt(ins_num)
      // const type_low = type.toLowerCase()
      // console.log('inscription num/claim: ', ins_num, claim)

      const query = db.collection("approved").where('inscriptionNum', '==', Number(ins_num));
      query.get().then((snapshot) => {
        if (snapshot.empty) {
          
          var docData = {
            inscriptionNum: ins_num_int, 
            tags: claimArray,
            dateTimeUploaded:dateTimeUploaded,
            // dateTimeInscribed:dateTimeInscribedConv,
            // lastDateTransfered:lastDateTransferedConv,
            // img:imgg,
            satRarity:sat_rarity,
            type:content_type,
            uploader:uploader,
            buyLink:id,
            dispute:disputeArray,
            tag_color:tagColorArray,
            uploader_email: email,
            // TODO: test this
            search_array: merged_search_arr
          }

          sixRef.set(docData).then(() => {
            addDocumentAndDeleteOldest()
        })
    
            approvedRef.set(docData).then(() => {
              // console.log("Document successfully written!");
              // setShow(false)
              // setInsNum(null)
              // setClaim(null)
              alert('Your claim request has been uploaded successfully')
          })
          
        } else {
          // // let them know which one already exists
          // const tagz = snapshot.docs.map(doc => doc.data())
          // const ins_num_rep = tagz[0].inscriptionNum
          // setRepetoMess('Sorry, Inscription #' +  ins_num_rep + ' has already been declared, pls try again')
          const uid_arr = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))
          const uid = uid_arr[0].id
          const collectionRef = db.collection('approved');
          const documentRef = collectionRef.doc(uid);

          // Update the document
          documentRef.update({
            tags: firebase.firestore.FieldValue.arrayUnion(...claimArray),
            search_array: firebase.firestore.FieldValue.arrayUnion(...merged_search_arr)
          })
            .then(() => {
            alert('Your tags has successfully been added to Inscription #' +  ins_num_int)
          })
            .catch((error) => {
              alert('error adding tags, please try again')
            });

          // alert('Your tags has successfully been added to Inscription #' +  ins_num_int)
        
        }})

     
    }

    function goBuy(buy_link){
      mixpanel.track('goBuy', {
        time: new Date().toLocaleString(),
        // buy_link: 'https://ordinalswallet.com/inscription/'+buy_link,
        buy_link: 'https://ordinals.com/inscription/'+buy_link,

      })

      // window.open('https://ordinalswallet.com/inscription/'+buy_link)
      window.open('https://ordinals.com/inscription/'+buy_link)
    }

    function miniSearch(tag){
      // setData(tag)
      mixpanel.track('miniSearch', {
        time: new Date().toLocaleString(),
        tag: tag,
      })
      
      if (tag == null){
        alert('Kindly write a search word')
        return;
      }

      setDisplayedCardsCount(20)
      setDisplayedTagsCounts(10)
        data_low = tag.toLowerCase();
        data_up = tag.charAt(0).toUpperCase() + tag.slice(1);
        if(data_up == null){
          setShowIfEmpty(false)
        }else{
          setShowIfEmpty(true)
        }
        // var data_low_rep = data_low.replace(' ', ',')
        // const data_lowArray = data_low.split(' ');
        // data_low.push(data_low)
        const query = db.collection("approved").where('search_array', 'array-contains', data_low);
        query.get().then((snapshot) => {
          if (snapshot.empty) {
            alert("sorry can't find any result")
            // return (
            //   <Alert variant="danger" onClose={() => setShow(false)} dismissible>
            //     <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            //     <p>
            //       sorry can't find any result
            //     </p>
            //   </Alert>
            // );
          } 
          else {
            // console.log(snapshot.docs.map(doc => doc.data()));
            const rearranged_result = rearrangeDictionaries(snapshot.docs.map(doc => doc.data()))
            // console.log(rearranged_result)
            setSixer(rearranged_result)
            let tagz = rearranged_result
            // console.log(tagz['tag_color'])
            tagz.forEach((arr,i) => {
              let col = []
              arr['tag_color'].forEach(tagz_arr => {
                col.push(tagz_arr);
              })
              colors[i] = col;
              // col = []
            });
            // console.log(colors)
        }
        });
    }

    function contest(tag, color, image, dispute, index, uploader){

      mixpanel.track('contest', {
        time: new Date().toLocaleString(),
        tag: tag,
      })

      // console.log(dispute[index])
      if (dispute[index] == '0'){
        // tick the first choice
        checked1 = ''
        checked2 = ''
      }
      else if (dispute[index] == '1'){
        // tick the first choice
        checked1 = 'checked'
        checked2 = ''
      }else if(dispute[index] == '2'){
        // tick the second choice
        checked1 = ''
        checked2 = 'checked'
      }else if(dispute[index] == '3'){
         // tick both choices
         checked1 = 'checked'
         checked2 = 'checked'
      }
      nick_name = uploader
      img_global = image
      tag_global = tag.toUpperCase()
      if(color == 'outline-danger'){
        tag_info = "This naming is currently been DISPUTED on grounds of: "
        fnt_col = "red"
        setShowDisputeMessage(true)
      }else if(color == 'outline-warning'){
        tag_info = "Dispute this Naming? Fill the form below"
        fnt_col = "black"
        setShowDisputeMessage(false)
      }
      article = getArticle(tag_global)
      handleContestShow()
    }
 
    const the_words = ["earth", "sun", "eiffel tower", "grand canyon"
                        , "Beatles"]
    function getArticle(word) {
      // Convert the word to lowercase for easier comparison
      const lowercaseWord = word.toLowerCase();
    
      // Words that begin with a vowel sound
      const vowelSounds = ['a', 'e', 'i', 'o', 'u'];

      if (the_words.includes(lowercaseWord)){
        return 'the';
      }
    
      // Check if the word starts with a vowel sound
      if (vowelSounds.includes(lowercaseWord[0])) {
        return 'an';
      }
    
      // Check if the word starts with a consonant sound
      if (/^[bcdfghjklmnpqrstvwxyz]/.test(lowercaseWord)) {
        return 'a';
      }
    
      // Default case: assume "the" should be used
      return 'the';
    }
    
    // console.log('loaded')
   // get data from input
   function getData(val)
   {
     setData(val.target.value)
     // setPrint(false)
     // console.warn(val.target.value)
   }


   function getCurrentDateTimeString() {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
  
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
  
    const formattedDateTime = `${day}/${month}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    
    return formattedDateTime;
  }
  
  // const currentDateTimeString = getCurrentDateTimeString();
  // console.log(currentDateTimeString);


   function convertDateFormat(dateString) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    // Split the input string into date and time
    const [datePart, timePart] = dateString.split(' AT ');
  
    // Extract day, month, and year from the date part
    const [day, monthName, year] = datePart.split(' ');
  
    // Find the index of the month in the 'months' array
    const monthIndex = months.findIndex(month => month.toUpperCase() === monthName.toUpperCase());
  
    // Create a new Date object with the extracted date and time
    const dateObj = new Date(`${monthIndex + 1}/${day}/${year} ${timePart}`);
  
    // Format the date as desired: 'DD/MonthName/YYYY HH:mm:ss'
    const formattedDate = `${dateObj.getDate()}/${months[dateObj.getMonth()]}/${dateObj.getFullYear()} ${dateObj.toLocaleTimeString()}`;
  
    return formattedDate;
  }

  function getPositionName(number) {
    if (number === 1) {
      return number + "st";
    } else if (number === 2) {
      return number + "nd";
    } else if (number === 3) {
      return number + "rd";
    } else {
      return number + "th";
    }
  }


   const userAction = async () => {
    // read all entities
    fetch("https://api.opensea.io/api/v1/collection/" + collection_name + "/stats", {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
         "accept": "*/*"
      }
    })
    .then(response => response.json())
    .then(response => {
        setFp(response.stats.floor_price)
        showing = false
    })
    .catch(err => { console.log(err); 
    });
  } 

  // function convert(){
    const convert = async () => {
      const db = firebase.firestore();
      const collectionRef = db.collection('approved');

      try {
        // const querySnapshot = await collectionRef.where('tags', 'array-contains', 'games').get();
        const querySnapshot = await collectionRef.where('inscriptionNum', 'in', [
          1000370
        ]).get();
        console.log('querySnapshot: ', querySnapshot)
        // Iterate over the query results
        querySnapshot.forEach(async (doc) => {
          const docRef = collectionRef.doc(doc.id);
          console.log('docRef: ', docRef)
          // Update the 'tag' field with 'monochrome'
          await docRef.update({ search_array: ['art'],
          tags:['art']
        });
          console.log('success',)
        });
      } catch (error) {
        console.error('Error querying Firestore:', error);
      }
  }
// }

// Function to delete documents with inscriptionNum within a range
// async function deleteDocumentsInRange() {
//   // Create a Firestore instance
//   const firestore = firebase.firestore();
//   const collectionRef = firestore.collection('approved');
//   const batchSize = 5; // Batch size for deleting documents

//   // Query documents with inscriptionNum within the desired range
//   const querySnapshot = await collectionRef
//     .where('inscriptionNum', '>=', 999999)
//     .where('inscriptionNum', '<=', 1000050)
//     .get();

//   // Delete documents in batches
//   let batchCount = 0;
//   let batch = firestore.batch();

//   querySnapshot.forEach((doc) => {
//     batch.delete(doc.ref);
//     batchCount++;

//     // Commit the batch after reaching the batch size
//     if (batchCount === batchSize) {
//       batch.commit();
//       batchCount = 0;
//       batch = firestore.batch();
//     }
//   });

//   // Commit the remaining documents in the last batch
//   if (batchCount > 0) {
//     batch.commit();
//   }
// }

// // Call the function to delete documents within the range
// deleteDocumentsInRange()
//   .then(() => {
//     console.log('Documents deleted successfully');
//   })
//   .catch((error) => {
//     console.error('Error deleting documents:', error);
//   });
    
  
  const getNFTs = async (e) => {
    // var cretaria_val = document.querySelector('input[name="group1"]:checked')
    // if (cretaria_val==null){
     cretaria_val = 'collection'
    // } else {
    //  cretaria_val = cretaria_val.value
    // }

    var ethNFTz
    if (e) {
      e.preventDefault();
    }
  
    // alchemy get ENS
    const config = {
      apiKey: 'ggiH6NvQIpyItpQK9r8gx_w4EODU6Ii7',
      network: Network.ETH_MAINNET,
    };
  
    const alchemy = new Alchemy(config);
    var selection_result = []
  
    // fetchAddress()
    const walletAddress = data; // replace with wallet address
    const ensContractAddress = data;
    if (cretaria_val == 'collection'){
      ethNFTz = await alchemy.nft.getNftsForContract(ensContractAddress)
      var col_name = ethNFTz.nfts[0].contract.name
     col_name = col_name.toLowerCase();
     col_name = col_name.replace(' ','')
      // # get collection name
      // # collection_name = 'ensmaxis'
      collection_name = col_name

      // # get floorprice data
      userAction()   
       

      //TODO: get selling price
      var price = 150

      var image_link_one
      var arrayLength = ethNFTz.nfts.length;
      // var arrayLength = 1
      for (var i = 0; i < arrayLength; i++){
        var tokenType = ethNFTz.nfts[i].tokenType
        var tokenID = ethNFTz.nfts[i].tokenId
        var contractAdd = ethNFTz.nfts[i].contract.address
        var collection = ethNFTz.nfts[i].contract.name
        var title = ethNFTz.nfts[i].title;
        var anime_url = ethNFTz.nfts[i].rawMetadata.animation_url;
        image_link_one = ethNFTz.nfts[i].rawMetadata.image;
        if (image_link_one != undefined) {
          var col_img = ethNFTz.nfts[i].rawMetadata.image
          if(image_link_one.includes('ipfs:')){
            var image_link = image_link_one.replace(':','')
            image_link = 'https://ipfs.io/'.concat(image_link)
            // console.log(image_link)
            // selection_result = ethNFTz.ownedNfts.map((ownedNfts) => ({'title': ownedNfts.title, 'image': image_link, 'collection': ownedNfts.contract.name, 'contractAdd': ownedNfts.contract.address, 'tokenID': ownedNfts.tokenId, 'tokenType': ownedNfts.tokenType}))
            selection_result[i] = ({'title': title, 'image': image_link, 'collection': collection, 'contractAdd': contractAdd, 'tokenID': tokenID, 'tokenType': tokenType, 'collectionImage': col_img})
          }else{
            // selection_result = ethNFTz.ownedNfts.map((ownedNfts) => ({'title': ownedNfts.title, 'image': ownedNfts.rawMetadata.image, 'collection': ownedNfts.contract.name, 'contractAdd': ownedNfts.contract.address, 'tokenID': ownedNfts.tokenId, 'tokenType': ownedNfts.tokenType}))
            selection_result[i] = ({'title': title, 'image': image_link_one, 'collection': collection, 'contractAdd': contractAdd, 'tokenID': tokenID, 'tokenType': tokenType, 'collectionImage': col_img})

          }
              } else {
                // var image_link_one = toString(ethNFTz.ownedNfts[i].rawMetadata.image)
                // if(image_link_one.includes('ipfs:')){
                //   var image_link = ethNFTz.ownedNfts[i].rawMetadata.image.replace(':','')
                //   image_link = 'https://ipfs.io'.concat(image_link)
                //   console.log(image_link)
                //   selection_result = ethNFTz.ownedNfts.map((ownedNfts) => ({'title': ownedNfts.title, 'image': image_link, 'collection': ownedNfts.contract.name, 'contractAdd': ownedNfts.contract.address, 'tokenID': ownedNfts.tokenId, 'tokenType': ownedNfts.tokenType}))
                // }else{
                  selection_result[i] = ({'title': title, 'image': anime_url, 'collection': collection, 'contractAdd': contractAdd, 'tokenID': tokenID, 'tokenType': tokenType, 'collectionImage': col_img})
                  // }
                // selection_result = ethNFTz.ownedNfts.map((ownedNfts) => ({'title': ownedNfts.title, 'image': ownedNfts.rawMetadata.image, 'collection': ownedNfts.contract.name, 'contractAdd': ownedNfts.contract.address, 'tokenID': ownedNfts.tokenId, 'tokenType': ownedNfts.tokenType}))
              }
      }
      // return;
    }
    // else{
    //   ethNFTz = await alchemy.nft.getNftsForContract(ensContractAddress);
    // }
    showing = true;
    // console.log(ethNFTz)

      // setResult([])
      selectNFT(selection_result)
      // console.log('selection_result')
      // console.log(selection_result)  
      // NFT.handleShow();
    
      function resizeImage() {
        var img = document.getElementById('image');
        img.style.width = '100%';
        img.style.height = 'auto';
      }      

      
    }

    function yesShow(){
      mixpanel.track('yesShow', {
        time: new Date().toLocaleString(),
        data: data,
      })
      handleAskClose()
      handleAddShow()
    }

    const fetchData = async () => {
      try {
        const id = ins_num; // Replace {id} with the actual ID value
        const response = await fetch(`https://api.hiro.so/ordinals/v1/inscriptions/${id}`);
        if (response.ok) {
          jsonData = await response.json();
          // var id = jsonData.id
          // var type = jsonData.content_type
          // var rarity = jsonData.sat_rarity
          // const insjson = JSON.stringify(jsonData);
          // console.log(jsonData)
        } else {
          console.log('Error: Unable to fetch data');
        }
      } catch (error) {
        console.log('Error: ' + error.message);
      }
      uploadClaimRequest2()
    };

    // const FileCard = ({ fileUrl, fileType }) => {
      const renderFileContent = ( fileUrl, fileType, url2, num ) => {
        // console.log(fileType)
        if (fileType.includes('application/pdf')) {
          // console.log('pdf')
          return (
            <div style={{borderRadius:10, height:"300px"}}>
             <h4>PDF File:</h4>
              <embed src={url2} type="application/pdf" width="100%" height="300px" object-fit="cover"/>
            </div>
          )
        } else if (fileType.includes('text/html;charset=utf-8')) {
          return (
              <div style={{borderRadius:10, height:"300px"}}>
                <h4>www.inscription{num}.com</h4>
                <iframe src={url2} title="HTML File" width="100%" height="400px" object-fit="cover"/>;
              </div>
          )
        } else if (['image/png', 'image/png', 'image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(fileType)) {
          // console.log('png')
          return <img  style={{borderRadius:10, height: '300px', width: 'auto'}} src={fileUrl} alt="Image File" className="img-fluid"  />;
        } else if (fileType.includes('audio/mpeg')) {
          // console.log('mp3')
          // TODO:videos/mp4 #273
          return (
            <audio controls>
              <source src={url2} type="audio/mpeg" />
            </audio>
          );
        } else if (fileType.includes('text/plain;charset=utf-8')) {
          // console.log('mp3')
          return (
            <div style={{borderRadius:10}}>
              <h4>Text File:</h4>
              <iframe style={{background:'white'}} src={url2} title="Text File" width="100%" height="245px" object-fit="cover" />
            </div>
          );
           } else if (fileType.includes('image/svg+xml')) {
            return (
              <img style={{borderRadius:10, height:"300px", width: 'auto'}}
              src={fileUrl}
              alt="Image"
            />
            );
             } else if (fileType.includes('video/mp4')) {
              return (
                <video controls>
                <source src={fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              );
               }
                else {
          // console.log('nothing')
          return <p>Unsupported file type</p>;
        }

      
      };
    // }


  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`} 
    >
      <div style={{ flex: 1 }} class="container" >
      <nav bg="dark" class="navbar navbar-expand navbar-dark" style={{"padding":"0px", margin:0}} >
        <a href="https://www.ordinalNFTs.com" class="navbar-brand" style={{"color": name_col, margin:5}} > OrdinalNFTs.com </a>
        <button
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button>
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          
          </ul>
        </div> */}
         <div onClick={handleShow} style={{ color: "#BFBAB2", marginLeft:30}} class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          <a href="#" onClick={handleShow} style={{ color: ins_color, cursor: "pointer" }}>
            +Inscription
          </a>
          </ul>
        </div>
        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div>
        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div>
        <div style={{align:'right'}}class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div>
        {/* <Button onClick={toggleDarkMode}>Upload</Button> */}
      
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          Upload
          </ul>
        </div> */}
        <span class="navbar-text">
          {/* <Form className="dark-mode-toggle" style={{ borderColor: darkMode ? 'dark-mode' : '' , color :color}}>
          <Form.Check 
          reverse='True'
            type="switch"
            id="darkModeSwitch"
            onChange={toggleDarkMode}
            label=""
          />
        </Form>  */}
        </span>
      </nav>
    </div>


    <Form
    onSubmit={searchTags}>
      <Form.Group className="mb-3" controlId="formBasicEmail" style={{ margin: 15, marginTop:35}}>
      {/* <h5 style={{display: 'flex',  justifyContent:'left', alignItems:'center', height:'5vh', marginLeft: 5}}>
        Find the very First Inscription of a/an .... ?
      </h5> */}
      <div style={{textAlign: "center"}}>  <small class="text-muted"> 
      {/* <b style={{ color: "#FFC300"}}>Alpha:</b> <b style={{ color: "#B77B09"}}>  Collect 1st Appearances on Bitcoin. </b>  */}
      <h5> <b style={{ color: "#FFAD09"}}> Find the Very First Inscription of ___  </b> </h5> </small></div>
        <InputGroup hasValidation>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="e.g: Meme"
              aria-describedby="inputGroupPrepend"
              onChange={getData}
              required
              style={{ marginTop: 20, borderRadius:50, height: 75}}
              // onKeyDown={searchTags}
              // variant="outline-dark"
            />
            <Form.Control.Feedback type="invalid">
              Please input a search word or phrase.
            </Form.Control.Feedback>
          </InputGroup>

          <div class="row">
          <div class="col text-center">
            {/* <Button style={{ margin: 5, fontSize:25, marginTop: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={handleShow}>
              <img src={photo} width="35" />
            </Button> */}
            <Button style={{ margin: 5, fontSize:25, marginTop: 35, borderRadius:0, width: 160, height: 65}} variant="warning" onClick={searchTags}>
              <img src="./searchw.png" width="40" />
            </Button>
          </div>
        </div>
       
      </Form.Group>
    </Form>


      <div>
      <div class="row">
        <div class="col text-center">
        {/* <div style={{ textAlign: "Left", marginLeft:10, marginTop: 30}}>  <small class="text-muted"> <b> </b> <h5> <b style={{ color: "#372502"}}> Recently Added: </b> </h5> </small></div> */}
            <Row className="g-2" style={{ margin: '5px -2.5px' }}>
              {sixers.slice(0, displayedCardsCount).map((sixer, id) => 
                <Col xs={12} sm={12} md={4} lg={3} style={{ marginBottom: 10 }}>
                <Card 
                className={darkMode ? 'dark-mode' : ''}
                type="button" class="list-group-item list-group-item-action" 
                style={{marginTop: 60, height: '300px', borderRadius:10, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}
                >


                <Card style={{height: "300px", borderRadius:10}} className={darkMode ? 'dark-mode' : ''} onClick={()=> goBuy(sixer.buyLink)}>
                 {/* {renderFileContent("https://ordinals.com/content/"+ sixer.buyLink , sixer.type)}  */}
                 {renderFileContent("https://api.hiro.so/ordinals/v1/inscriptions/"+ sixer.inscriptionNum + "/content", sixer.type, "https://ordinals.com/content/"+ sixer.buyLink, sixer.inscriptionNum)} 
                </Card>
              </Card>

              {showIfEmpty &&
                <ListGroup style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}} 
                className={`list-group-flush ${darkMode ? 'dark-mode' : ''}`}
                // onClick={'()=> contest(tag, colors[id][index], sixer.img, sixer.dispute, index, sixer.uploader)'}
                >
                  <ListGroup.Item 
                      className={id === 0 ? 'glitter-effect' : ''} // Add the glitter-effect class conditionally for id === 0
                      >     
                      {id === 0 ? (
                        <img src={'./cup.png'} alt="cup" width="30" />
                      ) : (
                        <img src={'./medal.png'} alt="medal" width="30" />
                      )} 
                  <div style={{color: "#34332B", fontSize:20}}> 
                    <b> {getPositionName(id + 1)}</b> Appearance of <b>{data_up}</b>
                  </div></ListGroup.Item>
                </ListGroup>
                }

                <Card.Body style={{ height: "100%", marginTop:15}}>
                  <Card.Title style={{ marginBottom:15}}> 
                      Inscription #{sixer.inscriptionNum}
                  </Card.Title>
                  {/* {renderFileContent("https://api.hiro.so/ordinals/v1/inscriptions/" + sixer.buyLink + "/content", sixer.type)} */}
                  <Card.Text>
                  {sixer.tags
                    .filter((tag) => tag.length < 20) // Filter tags with less than 20 characters
                    .slice(0, displayedTagsCounts) // Limit the number of displayed tags to 10
                    .map((tag, index) => (
                      <Button
                        onClick={() => miniSearch(tag)}
                        style={{ borderRadius: 50 }}
                        variant={colors[id] && colors[id][index] ? colors[id][index] : 'outline-danger'}
                        key={index}
                      >
                        {tag}
                      </Button>
                    ))}
                  </Card.Text>

                      {/* Show the 'Load More' button if the number of displayed tags is less than the total number of tags */}
                      {displayedTagsCounts < sixer.tags.length && (
                        <Button style={{margin:5}} onClick={loadMoreTags} variant="secondary">
                          More?
                        </Button>
                      )}
                      
                      {(displayedTagsCounts > 10) && (sixer.tags.length > 10) && (
                        <Button style={{margin:5}} onClick={loadLessTags} variant="secondary">
                          Less?
                        </Button>
                      )}
                 
                </Card.Body>
              </Col>
              )}
            </Row>

            
          {displayedCardsCount < sixers.length && (
          <Button style={{color: 'white', margin: 5, fontSize:25, marginTop: 80, borderRadius:10, width: 140, height: 70}} onClick={loadMoreCards} variant="warning">
            More?
          </Button>
        )}
            </div>
          </div>
      </div>

      
   
      

      {/* <React.Fragment>
        <NFT selections={selections}/>
        <Predictions predictions={predictions}/>
      </React.Fragment> */}

      <Modal show={showContest} onHide={handleContestClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          <img src="./leaderboard.png" width="20" /> <small class="text-muted"> {tag_global} Dispute Claim?<h5> <b style={{ color: "#372502"}}>  </b>  </h5> </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <>
        <div class="row">
            <div class="col text-center">
              <Card.Img style={{ padding: 10, marginBottom: 20, width: 200}} variant="top" 
              src={img_global}
              />
            </div>
          </div>

          <div class="row">
            <div class="col text-center" style={{ color: fnt_col}}>
              {tag_info}
            </div>
          </div>
        
          {showDisputeMessage &&  
            <div class="row">
            <div class="col text-center">
              <img src="./radio.png" width="10" /> { "Differences between " + article + " " + tag_global +" and " + tag_global + " Art"}
            </div>
          </div>
           }

          <Form style={{ padding: 10, marginTop: 0}}>
          {/* <div class="row">
            <div class="col text-center" style={{ }}> */}
            {/* </div>
          </div> */}
      {/* {['checkbox'].map((type) => ( */}
        {/* <div key={`inline-${type}`} className="mb-3"> */}
          {/* <Form.Check
            inline
            label={"Differences between " + article + " " + tag_global +" and " + tag_global + " art"} 
            name="group1"
            checked={checked1}
            type={type}
            id={`inline-${type}-1`}
          /> */}
          {/* <Form.Check
            inline
            name="group1"
            label={"There is an earlier inscription of " + article + " " + tag_global + " inscribed before the above."}
            type={type}
            checked={checked2}
            id={`inline-${type}-2`}
          /> */}
          {/* <Form.Check
            inline
            label="3 (disabled)"
            type={type}
            name="group1"
            id={`inline-${type}-3`}
          /> */}
        {/* </div> */}
      {/* ))} */}

        

          <FloatingLabel style={{ marginTop: 30, margin:10, borderRadius: 0}}
            onChange={(e) => setClaimDispute(e.target.value)}
            controlId="floatingInput"
            label="Add dispute? "
            className="mb-3"
          >
            <Form.Control type="name" placeholder="Add dispute?" />
              <Form.Text className="text-muted">
              State proof if applicable.
              </Form.Text>
          </FloatingLabel>

          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
            onChange={(e) => setDisputeEmail(e.target.value)}
            controlId="floatingInput"
            label="your email address?"
            type="email"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="your email address?" />
        </FloatingLabel>

    </Form>       
        </>
     
        </Modal.Body>
       
        {/* <Modal.Footer> */}
        <div class="row">
          <div class="col text-center">
              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 20, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={uploadDispute}>
                <img src="./swords.png" width="25" />
                {/* dispute */}
                {/* Contest Title */}
              </Button>

              <ListGroup className="list-group-flush">
                  <ListGroup.Item > <div style={{ color: "#34332B", fontSize:15}}> Contributed by <b>{nick_name}</b> </div></ListGroup.Item>
                  {/* <ListGroup.Item>Last Transfered:	3 March 2023 / 23:19:50</ListGroup.Item> */}
                  {/* <ListGroup.Item>Vestibulum at eros</ListGroup.Item> */}
                </ListGroup>
          </div>
        </div>
  
        {/* </Modal.Footer> */}
      </Modal>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submit / Remove Inscription Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setInsNum(e.target.value)}
            controlId="floatingInput"
            label="inscription number?"
            type="number"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="Inscription Number #?" />
          </FloatingLabel>

          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setClaim(e.target.value)}
            controlId="floatingInput"
            label="tag(s)? e.g audio file, mp3, human voice"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="Description? e.g First selfie of a human" />
              <Form.Text className="text-muted">
              <b>Important:</b> Seperate more than 1 tag with ","
              </Form.Text>
          </FloatingLabel>

          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setEmail(e.target.value)}
            controlId="floatingInput"
            label="Email to get rewards?"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="uploader email?" />
          </FloatingLabel>
          <Form.Group style={{ marginTop: 15, margin:20}} className="mb-3" controlId="formBasicCheckbox">
          <Form.Check onChange={(e)=>agreeCheck(e.target.checked)} type="checkbox" label="I swear, the information is accurate." />
          {/* <Form.Check type="checkbox" label="I agree to Begin & End this Challenge on time" /> */}
        </Form.Group>

        <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setUploader(e.target.value)}
            controlId="floatingInput"
            label="your signature?"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="uploader?" />
          </FloatingLabel>

        </>

        <h7 style={{color: 'red', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        {repeto_mess}
        </h7>
       
        </Modal.Body>
       
        {/* <Modal.Footer> */}
        <div class="row">
          <div class="col text-center">
              {/* <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={uploadClaimRequest}> */}
                {/* <img src="./photo.png" width="35" /> */}
              {/* </Button> */}

              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={deleteTags}>
              <img src="./removetag.png" width="35" />
              </Button>

              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={fetchData}>
              <img src="./photo.png" width="35" />
              </Button>
             
          </div>
        </div>
  
        {/* </Modal.Footer> */}
      </Modal>



      <Modal show={showAsk} onHide={handleAskClose}>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>       
          <div>
          Sorry, cannot find your search.
          Would you like to upload the <b>inscription number</b> so others can find it?
          </div>
        <div class="row">
          <div class="col text-center">
          
              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={yesShow}>
              {/* <img src="./photo.png" width="35" /> */}
              yes
              </Button>

              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={handleAskClose}>
              {/* <img src="./photo.png" width="35" /> */}
              no
              </Button>
             
          </div>
        </div>
       
        </Modal.Body>
       
        {/* <Modal.Footer> */}
  
        {/* </Modal.Footer> */}
      </Modal>


      <Modal show={showAdd} onHide={handleAddClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Inscription Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setInsNum(e.target.value)}
            controlId="floatingInput"
            label="inscription number?"
            type="number"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="Inscription Number #?" />
          </FloatingLabel>

          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setClaim(e.target.value)}
            controlId="floatingInput"
            label="tag(s)?"
            className="mb-3"
          >
            <Form.Control type="name" defaultValue={data} placeholder="Description? e.g First selfie of a human" />
              <Form.Text className="text-muted">
              <b>Important:</b> Seperate more than 1 tag with ","
              </Form.Text>
          </FloatingLabel>

          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setEmail(e.target.value)}
            controlId="floatingInput"
            label="Email to get rewards?"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="uploader email?" />
          </FloatingLabel>
          <Form.Group style={{ marginTop: 15, margin:20}} className="mb-3" controlId="formBasicCheckbox">
          <Form.Check onChange={(e)=>agreeCheck(e.target.checked)} type="checkbox" label="I swear, the information is accurate." />
          {/* <Form.Check type="checkbox" label="I agree to Begin & End this Challenge on time" /> */}
        </Form.Group>

        <FloatingLabel style={{ margin: 20, borderRadius: 0}}
            onChange={(e) => setUploader(e.target.value)}
            controlId="floatingInput"
            label="your signature?"
            className="mb-3"
          >
            <Form.Control type="name" placeholder="uploader?" />
          </FloatingLabel>

        </>

        <h7 style={{color: 'red', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        {repeto_mess}
        </h7>
       
        </Modal.Body>
       
        {/* <Modal.Footer> */}
        <div class="row">
          <div class="col text-center">
          
              <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 25, marginBottom: 35, borderRadius:3, width: 120, height: 60}} variant="outline-warning" onClick={fetchData}>
              <img src="./photo.png" width="35" />
              </Button>
             
          </div>
        </div>
  
        {/* </Modal.Footer> */}
      </Modal>

    </div>
  );
}

export default App;
